 interface Paging {
  page: number
  SortField: string
  SortType: string
  search?: string
  limit: number
}

const totalPages = (totalRows: number, limit: number): number => {
  return Math.ceil(totalRows / limit)
}

export {
  totalPages,
  Paging
}
