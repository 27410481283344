
/* eslint-disable vue/no-unused-components */

import ApiService from "@/services/api.service";
import { Paging, totalPages } from "@/utils/Pagination";
import { AxiosResponse } from "axios";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import PaginationTable from '@/components/PaginationTable.vue';

export interface TopClient {
  name: string
  value: string
}
export interface Report {
  total_sales: number
  total_amount: number
  total_paid: number
  due: number
  name: string
  phone: string
  code: number
  id: number
}
export interface ClientReport {
  report: Report[]
  totalRows: number
}
export default defineComponent({
  name: 'Report',
  components: {
    PaginationTable,
  },
  setup(){

    const reports = ref<ClientReport>({
      report: [],
      totalRows: 0
    })

    const paginate = reactive<Paging>({
      page: 1,
      SortField: 'Name',
      SortType: 'desc',
      search: '',
      limit: 10
    })    

    const getLists = async() => {
      try {
        const { data }: AxiosResponse<ClientReport> = await ApiService.get('/report/client', {
          params: paginate
        })
        reports.value = data
      } catch (error) {
        console.log(error);
      }
    }

    watch(() => paginate.page, () => {
      getLists()
    })
    onMounted(() => {
      getLists()
    })

    const pages = computed(() => totalPages(reports.value.totalRows, paginate.limit))
    return {
      reports,
      paginate,
      pages
    }
  }
})
