
import { defineComponent } from "vue";

export default defineComponent({
  name: 'PaginationTable',
  props: {
    max: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const previous = () => {
      if (props.modelValue > 1) {
        emit('update:modelValue', props.modelValue - 1)
      }
    }
    const next = () => {
      if (props.modelValue < props.max) {
        emit('update:modelValue', props.modelValue + 1)
      }
    }
    const goTo = (to: number): void => emit('update:modelValue', to)
    return {
      previous,
      next,
      goTo
    }
  }
})
